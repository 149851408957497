import { Markdown } from 'components/Markdown/Markdown';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { SectionWrapper, H1 } from 'styles/Typography.styled';

export const Container = styled.div<{ isRounded: boolean }>(
    ({ isRounded, theme: { colors } }) => css`
        background-color: ${colors.white};
        border-radius: 0 0 0 306px;
        position: relative;

        &:before {
            background-color: ${isRounded ? colors.darkBlue : colors.white};
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
        ${mediaQueries.lg} {
            border-radius: 0 0 0 160px;
        }

        ${mediaQueries.md} {
            border-radius: 0px 0px 0px 85px;
        }
    `,
);

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    ${mediaQueries.sm} {
        margin-left: 0px;
        order: 2;
        padding: 0px 20px;
    }
`;

export const StyledTitle = styled(H1)(
    ({ theme: { colors, fontSizes } }) => css`
        color: ${colors.darkBlue};
        font-size: ${fontSizes.f56};
        margin-bottom: 24px;
        line-height: 70px;

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f36};
            line-height: 44px;
            margin-bottom: 16px;
            text-align: center;
        }
    `,
);

export const StyledSectionWrapper = styled(SectionWrapper)(
    ({ theme: { containerWidth } }) => css`
        display: grid;
        grid-gap: 70px;
        grid-template-columns: 525px 630px;
        max-width: ${containerWidth.motherPage};
        padding: 40px 0 140px;

        ${mediaQueries.lg} {
            overflow: hidden;
            grid-gap: 56px;
            grid-template-columns: 520px 590px;
        }

        ${mediaQueries.md} {
            grid-gap: 56px;
            grid-template-columns: 480px 440px;
            padding: 56px 16px;
        }

        ${mediaQueries.sm} {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 48px 0 64px;
        }

        ${mediaQueries.xs} {
            gap: 40px;
            padding: 8px 0 64px;
        }
    `,
);

export const Image = styled.img`
    border-radius: 0px 0px 231.5px 0px;
    max-width: 630px;
    object-fit: contain;
    ${mediaQueries.lg} {
        max-width: 580px;
    }

    ${mediaQueries.md} {
        height: 500px;
        max-width: 440px;
    }
    ${mediaQueries.sm} {
        border-radius: 0px 0px 85px 0px;
        height: unset;
        max-width: 100%;
        object-fit: cover;
    }

    ${mediaQueries.xs} {
        height: 228px;
        max-width: unset;
        width: 100%;
    }
`;

export const ImageContainer = styled.div`
    position: relative;

    ${mediaQueries.sm} {
        order: 1;
        padding-right: 28px;
        width: 100%;
    }
`;

export const HeroMarkdown = styled(Markdown)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) => css`
        padding: 0 35px 0 0;
        p {
            color: ${colors.motherText};
            font-size: ${fontSizes.f18};
            letter-spacing: ${letterSpacing.s};
            line-height: 32px;
            font-weight: ${fontWeight.regular};

            ${mediaQueries.xs} {
                font-size: ${fontSizes.f16};
                letter-spacing: ${letterSpacing.s};
                line-height: 28px;
            }
        }
        b,
        strong {
            font-weight: ${fontWeight.semiBold};
        }

        ${mediaQueries.xs} {
            text-align: center;
            padding: 0;
        }
    `,
);

export const DotsWrapper = styled.div`
    position: absolute;
    right: -26px;
    top: -28px;
    width: 104px;

    ${mediaQueries.sm} {
        right: 116px;
        top: -22px;
        width: 80px;
    }

    ${mediaQueries.xs} {
        right: 16px;
        top: -12px;
        width: 44px;
    }
`;

export const CircleWrapper = styled.div`
    left: -244px;
    position: absolute;
    top: 342px;
    width: 408px;

    ${mediaQueries.sm} {
        bottom: -114px;
        left: -100px;
        top: unset;
        width: 204px;
    }
`;
