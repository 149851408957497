import { Link } from 'gatsby';
import { Link as AnchorLink } from 'react-scroll';
import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            align-items: center;
            background-color: ${colors.white};
            display: flex;
            justify-content: space-between;
            padding: 48px 108px;
            position: sticky;
            top: 0;
            z-index: 999;

            @media (max-width: 1200px) {
                padding: 48px 38px;
            }

            ${mediaQueries.md} {
                padding: 24px 16px;
            }
        `,
);

export const LinksWrapper = styled.div`
    @media (max-width: 860px) {
        display: none;
    }
`;

export const LinkScrollTo = styled(AnchorLink)<{ isLogo?: boolean }>(
    ({ theme: { colors, fontWeight, fontSizes, letterSpacing }, isLogo }) =>
        css`
            border-bottom: 2px solid transparent;
            color: ${colors.motherText};
            cursor: pointer;
            font-size: ${fontSizes.f16};
            font-weight: ${fontWeight.regular};
            letter-spacing: ${letterSpacing.m};
            line-height: 24px;
            margin: 0 24px;

            ${mediaQueries.md} {
                margin: 0 12px;
            }

            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }

            ${!isLogo &&
            css`
                &:hover {
                    border-bottom: 2px solid ${colors.motherHover};
                    color: ${colors.motherHover};
                }

                &.active {
                    border-bottom: 2px solid ${colors.motherHover};
                    color: ${colors.motherHover};
                }
            `}
        `,
);
