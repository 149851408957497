import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const Container = styled.section`
    position: relative;
`;

export const Image = styled.img`
    height: 490px;
    max-width: unset;
    object-fit: cover;
    width: 100%;

    ${mediaQueries.sm} {
        border-radius: 0px 0px 30px 0px;
        height: 350px;
        object-fit: cover;
        width: 280px;
    }
`;

export const ImageGrid = styled.div(
    ({ theme: { containerWidth } }) => css`
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 auto;
        max-width: ${containerWidth.motherPage};
        padding: 0 0 16px;
        place-items: center;
        position: relative;

        ${mediaQueries.md} {
            padding: 8px 16px;
        }

        ${mediaQueries.sm} {
            padding: 0px 20px 54px;
            display: flex;
            justify-content: center;
        }
    `,
);

export const ImageContainer = styled.a`
    position: relative;
    height: 490px;
    ${mediaQueries.sm} {
        height: 350px;
    }
`;

export const ImageInfo = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.aliceBlue};
        border-radius: 0px 0px 30px 0px;
        bottom: -16px;
        display: grid;
        grid-gap: 5px;
        left: -16px;
        padding: 28px 40px 24px;
        position: absolute;
        width: 374px;

        ${mediaQueries.sm} {
            left: -10px;
            padding: 16px;
            width: 298px;
        }
    `,
);

export const UppercaseTitle = styled.h6(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.darkBlue};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.l};
            line-height: 16px;
            text-transform: uppercase;
        `,
);

export const InvestmentName = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        color: ${colors.motherText};
        font-size: ${fontSizes.f18};
        font-weight: ${fontWeight.semiBold};
        line-height: 28px;
        letter-spacing: ${letterSpacing.m};
    `,
);
