import { Markdown } from 'components/Markdown/Markdown';
import { mediaQueries } from 'shared/breakpoints';
import AboutUsImg from 'images/about-us-box.png';
import AboutUsImgMobile from 'images/about-us-box-m.png';
import styled, { css } from 'styled-components';
import { SectionWrapper, SecondaryButton, H2 } from 'styles/Typography.styled';

export const Container = styled.div(
    ({ theme: { colors } }) => css`
        position: relative;

        &:before {
            content: '';
            background-color: ${colors.darkBlue};
            border-radius: 0 0 333px 0;
            height: 88%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;

            ${mediaQueries.sm} {
                border-radius: 0 0 85px 0;
                height: 92%;
            }
        }
    `,
);

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    ${mediaQueries.sm} {
        margin-left: 0px;
        order: 1;
    }
`;

export const StyledTitle = styled(H2)(
    ({ theme: { colors } }) => css`
        color: ${colors.white};
        margin-bottom: 24px;
    `,
);

export const StyledSectionWrapper = styled(SectionWrapper)(
    ({ theme: { containerWidth } }) => css`
        gap: 108px;
        max-width: ${containerWidth.motherPage};
        padding: 160px 0 146px;
        overflow-x: hidden;

        ${mediaQueries.md} {
            grid-gap: 48px;
            padding: 56px 16px;
        }

        ${mediaQueries.sm} {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 48px;
            padding: 48px 20px 54px;
        }
    `,
);

export const ImageWrapper = styled.div`
    display: flex;

    ${mediaQueries.sm} {
        order: 2;
    }
`;

export const Image = styled.img`
    max-width: 300px;
    object-fit: contain;

    &:first-child {
        margin-right: 36px;
    }

    ${mediaQueries.sm} {
        max-width: 100%;
    }

    ${mediaQueries.xs} {
        max-width: 132px;

        &:first-child {
            margin-right: 16px;
        }
    }
`;

export const InfoBox = styled.div`
    background-image: url(${AboutUsImg});
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-gap: 24px;
    margin: 0 auto;
    max-width: 1015px;
    padding: 64px 88px;
    place-items: center;
    position: relative;

    ${mediaQueries.md} {
        max-width: 700px;
        padding: 34px 58px;
    }

    ${mediaQueries.sm} {
        max-width: 600px;
        padding: 24px 36px;
    }

    ${mediaQueries.xs} {
        background-image: url(${AboutUsImgMobile});
        background-size: 100% 100%;
        height: 242px;
        margin: 0 35px;
        padding: 40px 20px;
    }
`;

export const AboutUsMarkdown = styled(Markdown)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) => css`
        p {
            color: ${colors.darkBlue};
            font-size: ${fontSizes.f20};
            letter-spacing: ${letterSpacing.m};
            line-height: 32px;

            ${mediaQueries.md} {
                font-size: ${fontSizes.f14};
            }

            ${mediaQueries.xs} {
                font-size: ${fontSizes.f16};
                font-weight: ${fontWeight.regular};
                letter-spacing: ${letterSpacing.s};
                line-height: 24px;
            }
        }
        b,
        strong {
            font-weight: ${fontWeight.semiBold};
        }

        ${mediaQueries.xs} {
            text-align: center;
        }
    `,
);

export const IconWrapper = styled.div`
    bottom: 32px;
    left: -75px;
    position: absolute;
    width: 104px;

    ${mediaQueries.md} {
        bottom: 22px;
        left: -58px;
        width: 80px;
    }

    ${mediaQueries.sm} {
        left: -20px;
    }

    ${mediaQueries.xs} {
        bottom: -12px;
        left: -8px;
        width: 70px;
    }
`;

export const AboutUsDescriptionMarkdown = styled(Markdown)(
    ({ theme: { fontSizes, fontWeight, letterSpacing, colors } }) => css`
        p {
            color: ${colors.white};
            font-weight: ${fontWeight.regular};
            letter-spacing: ${letterSpacing.m};
            margin: 0 0 18px;
            :last-child {
                margin: 0;
            }

            ${mediaQueries.md} {
                font-size: ${fontSizes.f16};
            }

            ${mediaQueries.sm} {
                line-height: 24px;
            }
        }

        b {
            font-weight: ${fontWeight.semiBold};
        }
        strong {
            font-weight: ${fontWeight.semiBold};
        }
    `,
);

export const AboutUsButton = styled(SecondaryButton)(
    ({ theme: { colors, fontSizes } }) => css`
        border: none;
        color: ${colors.darkBlue};

        ${mediaQueries.md} {
            font-size: ${fontSizes.f14};
        }

        ${mediaQueries.xs} {
            font-size: ${fontSizes.f16};
            line-height: 24px;
            width: 100%;
        }
    `,
);
