import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';
import { SectionWrapper } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)(
    ({ theme: { containerWidth } }) => css`
        max-width: ${containerWidth.motherPage};
        display: flex;
        flex-direction: column;
        padding-bottom: 144px;

        .swiper-button {
            position: static;
            height: 32px;
            width: 32px;

            svg {
                height: 32px;
                width: 32px;
            }

            &:after {
                display: none;
            }
        }

        .swiper-button-next {
            margin-top: 0;
            margin-left: 30px;
            transform: rotate(180deg);
        }

        .swiper-button-prev {
            margin-top: 0;
        }

        .swiper {
            ${mediaQueries.md} {
                padding: 0 20px;
            }
        }

        ${mediaQueries.md} {
            padding: 10px 0 64px;
        }
    `,
);

export const HeaderSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ArrowsWrapper = styled.div`
    width: 20%;
    padding-top: 240px;
    display: flex;
    align-self: start;
    justify-content: flex-end;

    ${mediaQueries.md} {
        padding-top: 180px;
        padding-right: 20px;
    }
`;

export const SwiperWrapper = styled(Swiper)`
    display: block;
    width: 100%;
`;

export const MobileListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${mediaQueries.sm} {
        padding: 0 30px;
    }
`;

export const LoadMoredButton = styled.button(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            align-items: center;
            box-sizing: border-box;
            display: flex;
            font-size: ${fontSizes.f18};
            font-weight: ${fontWeight.bold};
            justify-content: center;
            letter-spacing: ${letterSpacing.m};
            line-height: 24px;
            padding: 14px 32px;
            text-align: center;
            text-decoration: none;
            background: ${colors.white};
            border: 2px solid ${colors.darkBlue};
            color: ${colors.darkBlue};
            cursor: pointer;
            margin: 0 36px;
            width: 100%;
            width: -moz-available;
            width: -webkit-fill-available;

            &:hover {
                color: ${colors.white};
                background: ${colors.darkBlue};
            }
        `,
);
