import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';

export const Container = styled.section(
    ({ theme: { colors } }) => css`
        position: relative;

        &:before {
            background-color: ${colors.darkBlue};
            content: '';
            height: 575px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    `,
);

export const Image = styled.img`
    height: 330px;
    max-width: unset;
    object-fit: cover;
    width: 100%;
`;

export const ImageContainer = styled.a`
    cursor: pointer;
    height: 330px;
    position: relative;
    width: 100%;
`;

export const ImageGrid = styled.div<{ isEven: boolean; isOne: boolean }>(
    ({ isEven, isOne, theme: { containerWidth } }) => css`
        display: grid;
        grid-gap: 50px 35px;
        grid-template-columns: 1fr 1fr;
        margin: 0 auto;
        max-width: ${containerWidth.motherPage};
        padding: 0 0 16px;
        place-items: center;
        position: relative;

        ${mediaQueries.md} {
            padding: 56px 16px;
        }

        ${mediaQueries.sm} {
            padding: 48px 20px 54px;
        }

        ${!isEven &&
        css`
            ${ImageContainer} {
                &:first-child {
                    grid-column: ${isEven ? 'auto' : '1 / -1'};
                    width: 100%;
                }
            }
        `}

        ${isOne &&
        css`
            ${Image} {
                &:first-child {
                    height: 490px;
                    max-width: unset;
                    object-fit: cover;
                    width: 100%;
                }
            }

            ${ImageContainer} {
                height: 490px;
            }
        `};
    `,
);

export const ImageInfo = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.aliceBlue};
        border-radius: 0px 0px 30px 0px;
        bottom: -16px;
        display: grid;
        grid-gap: 5px;
        left: -16px;
        padding: 28px 40px 24px;
        position: absolute;
        width: 374px;

        ${mediaQueries.sm} {
            left: 0;
            padding: 16px;
            width: 250px;
        }
    `,
);

export const UppercaseTitle = styled.h6(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) =>
        css`
            color: ${colors.darkBlue};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.semiBold};
            letter-spacing: ${letterSpacing.l};
            line-height: 16px;
            text-transform: uppercase;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f12};
            }
        `,
);

export const InvestmentName = styled.p(
    ({ theme: { colors, fontSizes, fontWeight, letterSpacing } }) => css`
        color: ${colors.motherText};
        font-size: ${fontSizes.f18};
        font-weight: ${fontWeight.semiBold};
        line-height: 28px;
        letter-spacing: ${letterSpacing.m};

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f14};
        }
    `,
);
